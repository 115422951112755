import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { createLocation, fetchLocation, fetchLocations, getLocation } from "../../app/reducers/Location/locationSlice";
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useEffect } from 'react';
import { createDispatchLocation } from '../../app/reducers/DispatchLocation/dispatchLocationSlice';

const AddDispatchLocation = () => {
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        onSubmit: (values, { resetForm }) => {
            dispatch(createDispatchLocation(values)).then((res) => {
                resetForm();
            });
        },
    });
    return (
        <PageWithCard heading="Add Dispatch Location">
            {/* {loading ? (
        <ClipLoader />
      ) : ( */}
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4"
            >
                <FormikInputGroup
                    label="Location Name"
                    name="name"
                    required
                    formik={formik}
                />
                <div>
                    <PrimaryButton type="submit">Create</PrimaryButton>
                </div>
            </form>
            {/* )} */}
        </PageWithCard>
    );
};

export default AddDispatchLocation;
