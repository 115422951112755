import { createSlice } from '@reduxjs/toolkit';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'products';

export const fetchProducts = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createProduct = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchProduct = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editProduct = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteProduct = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);

const productSlice = createSlice({
    initialState: generateStateSingle(SLICE_NAME),
    name: SLICE_NAME,
    reducers: {
        resetEditData: (state) => {
            state.elementEditData = null;
        },
    },
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchProducts, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchProduct, SLICE_NAME),
        ...createData.generateExtraReducers(createProduct, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteProduct, SLICE_NAME),
        ...editData.generateExtraReducers(editProduct, SLICE_NAME),
    },
});

export const getProducts = (state) => state.products;
export const { resetEditData } = productSlice.actions;

export default productSlice.reducer;
