import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
    editEmployee,
    fetchEmployee,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import { useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { object, string } from 'yup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import {
    fetchDispatchLocations,
    getDispatchLocation,
} from '../../app/reducers/DispatchLocation/dispatchLocationSlice';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';

const EditEmployeePage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { elementEditData, elementEditDataLoading } =
        useSelector(getEmployees);

    useEffect(() => {
        dispatch(fetchEmployee({ _id: id }));
    }, []);

    const { dispatchLocation } = useSelector(getDispatchLocation);

    useEffect(() => {
        dispatch(fetchDispatchLocations());
    }, []);

    const dispatchLocationData = useMemo(
        () => (dispatchLocation.docs ? dispatchLocation.docs : []),
        [dispatchLocation.docs]
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...elementEditData,
            password: '',
        },
        validationSchema: object({
            firstName: string().required(),
            username: string().required(),
        }),
        onSubmit: (values) => {
            console.log(values);
            dispatch(editEmployee(values));
        },
    });
    return (
        <PageWithCard>
            {elementEditDataLoading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4"
                >
                    <FormikInputGroup
                        label="System Id"
                        name="_id"
                        required
                        readOnly
                        formik={formik}
                    />
                    <FormikInputGroup
                        label="Phone"
                        name="username"
                        required
                        readOnly
                        formik={formik}
                    />
                    <FormikInputGroup
                        label="First Name"
                        name="firstName"
                        required
                        formik={formik}
                    />
                    <FormikInputGroup
                        label="Last Name"
                        name="lastName"
                        formik={formik}
                    />
                    <FormikSelectGroup
                        label="Dispatch Location"
                        name="location"
                        formik={formik}
                        options={generateOptions({
                            array: dispatchLocationData ?? [],
                            labelField: 'name',
                            valueField: '_id',
                        })}
                    />
                    <FormikInputGroup
                        label="Password"
                        name="password"
                        type="password"
                        formik={formik}
                    />
                    <FormikCheckbox
                        label="Status"
                        name="status"
                        formik={formik}
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </PageWithCard>
    );
};

export default EditEmployeePage;
