import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'company';

export const fetchCompanies = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editCompany = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createCompany = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteCompany = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchCompany = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);

const companiesSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchCompanies, SLICE_NAME),
        ...editData.generateExtraReducers(editCompany, SLICE_NAME),
        ...createData.generateExtraReducers(createCompany, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteCompany, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchCompany, SLICE_NAME),
    },
});

export const getCompanies = (state) => state.company;
export default companiesSlice.reducer;
