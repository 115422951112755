import React, { useEffect, useState } from 'react';
import EditModal from '../../components/infrastructure/Modals/EditModal';
import { Formik, useFormik } from 'formik';
import { fetchEditData } from '../../app/generators/generateThunks';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useDispatch, useSelector } from 'react-redux';
import {
    editProduct,
    fetchProduct,
    fetchProducts,
    getProducts,
    resetEditData,
} from '../../app/reducers/Products/productSlice';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { PRODUCT_TYPE } from '../../utils/dropdownOptions';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';

const EditProduct = ({ productEditId, setProductEditId, page }) => {
    const [showModal, setShowModal] = useState(false);
    const { elementEditData, editDataLoading, loading } =
        useSelector(getProducts);

    const dispatch = useDispatch();

    useEffect(() => {
        if (productEditId) {
            dispatch(fetchProduct({ _id: productEditId }));
            setShowModal(true);
        } else {
            setProductEditId(null);
            dispatch(resetEditData());

            setShowModal(false);
        }
    }, [productEditId]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: elementEditData,
        onSubmit: async (values) => {
            console.log(values);
            await dispatch(editProduct(values));
            await dispatch(
                fetchProducts({ populate: true, page, sort: { type: 1 } })
            );

            setShowModal(false);
        },
    });
    return (
        <ModalBasic
            modalOpen={showModal}
            setModalOpen={setShowModal}
            onModalClose={() => {
                console.log('modal close');
                dispatch(resetEditData());
                setProductEditId(null);
            }}
        >
            {editDataLoading || loading ? (
                <ClipLoader />
            ) : (
                <form
                    className="flex flex-col gap-4 p-4"
                    onSubmit={formik.handleSubmit}
                >
                    <FormikDirectImageUpload
                        name={'photo'}
                        label="photo"
                        location={'iml_group/products'}
                        formik={formik}
                    />
                    <FormikInputGroup
                        name={'_id'}
                        formik={formik}
                        label="System Id"
                        readOnly
                        required
                    />
                    <FormikInputGroup
                        name={'name'}
                        formik={formik}
                        label="Name"
                        required
                    />
                    <FormikSelectGroup
                        name={'type'}
                        formik={formik}
                        label="Type"
                        options={PRODUCT_TYPE}
                        required
                    />
                    <div>
                        <PrimaryButton
                            type="submit"
                            disabled={formik.isSubmitting}
                        >
                            Edit
                        </PrimaryButton>
                    </div>
                </form>
            )}
        </ModalBasic>
    );
};

export default EditProduct;
