import { useFormik } from 'formik';
import React from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import { PURCHASE_ORDER_STATUS } from '../../utils/dropdownOptions';
import { useDispatch } from 'react-redux';
import { createPurchaseOrder } from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import * as Yup from 'yup';

const CreatePurchaseOrder = () => {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            customer: '',
            product: '',
            notes: '',
            status: '',
            cyan: 0,
            magenta: 0,
            yellow: 0,
            black: 0,
            cleaningSolution: '',
        },
        validationSchema: Yup.object({
            customer: Yup.string().required(),
            product: Yup.string().required(),
            notes: Yup.string(),
            status: Yup.string(),
            cyan: Yup.number(),
            magenta: Yup.number(),
            yellow: Yup.number(),
            black: Yup.number(),
            cleaningSolution: Yup.string(),
        }),
        onSubmit: async (values) => {
            await dispatch(createPurchaseOrder(values));
            formik.resetForm();
        },
    });
    return (
        <PageWithCard heading="Create Purchase Order">
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-2"
            >
                <FormikAsyncSelect
                    formik={formik}
                    name={'product'}
                    label="Product"
                    getOptions={async (value) => {
                        try {
                            const string = QueryString.stringify({
                                search: value,
                                type: { $ne: 'MACHINE' },
                            });
                            const response = await authAxiosInstance.get(
                                `products?${string}`
                            );

                            const options = response?.data?.data?.docs?.map(
                                (ele) => ({
                                    label: ele.name,
                                    value: ele?._id,
                                })
                            );
                            return options;
                        } catch (error) {
                            console.log(error);
                        }
                    }}
                    required
                />
                <FormikAsyncSelect
                    formik={formik}
                    name={'customer'}
                    label="Customer"
                    getOptions={async (value) => {
                        try {
                            const string = QueryString.stringify({
                                search: value,
                            });
                            const response = await authAxiosInstance.get(
                                `customer?${string}`
                            );

                            const options = response?.data?.data?.docs?.map(
                                (ele) => ({
                                    label: ele.name,
                                    value: ele?._id,
                                })
                            );
                            return options;
                        } catch (error) {
                            console.log(error);
                        }
                    }}
                    required
                />
                <FormikSelectGroup
                    formik={formik}
                    name={'status'}
                    label="Status"
                    options={PURCHASE_ORDER_STATUS}
                />
                <FormikTextareaGroup
                    name="notes"
                    formik={formik}
                    label="Notes"
                />
                <FormikInputGroup
                    formik={formik}
                    name={'cyan'}
                    type="number"
                    label="Cyan"
                />
                <FormikInputGroup
                    formik={formik}
                    name={'magenta'}
                    type="number"
                    label="Magenta"
                />
                <FormikInputGroup
                    formik={formik}
                    name={'yellow'}
                    type="number"
                    label="Yellow"
                />
                <FormikInputGroup
                    formik={formik}
                    name={'black'}
                    type="number"
                    label="Black"
                />
                <FormikInputGroup
                    formik={formik}
                    name={'cleaningSolution'}
                    label="Cleaning Solution"
                />

                <div className="mb-2">
                    {formik.isSubmitting ? (
                        <ClipLoader />
                    ) : (
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    )}
                </div>
            </form>
        </PageWithCard>
    );
};

export default CreatePurchaseOrder;
