import React from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import { useDispatch } from 'react-redux';
import {
    editComplaint,
    fetchComplaints,
} from '../../app/reducers/Complaints/complaintSlice';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';

const CloseComplaintModal = ({
    isCloseComplaintBtnOpen,
    setIsCloseComplaintBtnOpen,
    closeComplaintData,
    page,
    complaintStatus,
    activeButtonIndex,
}) => {
    const dispatch = useDispatch();
    const resolutionFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...closeComplaintData,
            resolution: '',
            resolutionPhotos: closeComplaintData?.resolutionPhotos
                ? closeComplaintData.resolutionPhotos
                : [],
            resolutionVideos: closeComplaintData?.resolutionVideos
                ? closeComplaintData.resolutionVideos
                : [],
        },
        onSubmit: async (values) => {
            console.log(values);
            await dispatch(editComplaint({ ...values, status: 'closed' }));
            await dispatch(
                fetchComplaints({
                    populate: true,
                    page,
                    status: `${complaintStatus[activeButtonIndex]}`,
                })
            );
            setIsCloseComplaintBtnOpen(false);
            resolutionFormik.resetForm();
        },
    });
    return (
        <ModalBasic
            title={`Add Resolution`}
            setModalOpen={setIsCloseComplaintBtnOpen}
            modalOpen={isCloseComplaintBtnOpen}
        >
            <form
                onSubmit={resolutionFormik.handleSubmit}
                className="flex flex-col gap-3 p-3"
            >
                <FormikTextareaGroup
                    name="resolution"
                    formik={resolutionFormik}
                    label="Resolution"
                />
                <p>Photos</p>
                {resolutionFormik?.values?.product && (
                    <FormikProvider value={resolutionFormik}>
                        <FieldArray
                            name="resolutionPhotos"
                            render={(arrayHelpers) => {
                                return (
                                    <div className="flex flex-col">
                                        {resolutionFormik?.values?.photos?.map(
                                            (ele, i) => (
                                                <div className="flex items-center gap-2">
                                                    <FormikDirectImageUpload
                                                        label={`photo ${i + 1}`}
                                                        formik={
                                                            resolutionFormik
                                                        }
                                                        name={`photos.${i}`}
                                                        randomize
                                                        location={
                                                            'iml_group/complaints/'
                                                        }
                                                    />
                                                    <div>
                                                        <DangerButton
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                arrayHelpers.remove(
                                                                    i
                                                                );
                                                            }}
                                                        >
                                                            Remove
                                                        </DangerButton>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                        <div>
                                            <PrimaryButton
                                                onClick={(e) => {
                                                    e.stopPropagation();

                                                    arrayHelpers.push('');
                                                }}
                                            >
                                                Add Photo
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </FormikProvider>
                )}
                <p>Videos</p>

                {resolutionFormik?.values?.product && (
                    <FormikProvider value={resolutionFormik}>
                        <FieldArray
                            name="resolutionVideos"
                            render={(arrayHelpers) => {
                                return (
                                    <div className="flex flex-col">
                                        {resolutionFormik?.values?.videos?.map(
                                            (ele, i) => (
                                                <div className="flex items-center gap-2">
                                                    <FormikDirectFileUpload
                                                        label={`video ${i + 1}`}
                                                        formik={
                                                            resolutionFormik
                                                        }
                                                        name={`videos.${i}`}
                                                        randomize
                                                        location={
                                                            'iml_group/complaints/'
                                                        }
                                                    />
                                                    <div>
                                                        <DangerButton
                                                            onClick={(e) => {
                                                                e.stopPropagation();

                                                                arrayHelpers.remove(
                                                                    i
                                                                );
                                                            }}
                                                        >
                                                            Remove
                                                        </DangerButton>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                        <div>
                                            <PrimaryButton
                                                onClick={(e) => {
                                                    e.stopPropagation();

                                                    arrayHelpers.push('');
                                                }}
                                            >
                                                Add Video
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </FormikProvider>
                )}
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default CloseComplaintModal;
