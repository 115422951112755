import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchPurchaseOrders,
    getPurchaseOrders,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import { ClipLoader } from 'react-spinners';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import {
    fetchProducts,
    getProducts,
} from '../../app/reducers/Products/productSlice';
import {
    fetchCustomers,
    getCustomers,
} from '../../app/reducers/Customers/customerSlice';
import { exportCsv, generateOptions } from '../../utils/Utils';
import { PURCHASE_ORDER_STATUS } from '../../utils/dropdownOptions';
import PurchaseOrderStatusModal from './PurchaseOrderStatusModal';
import { useNavigate, useParams } from 'react-router-dom';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';

const ViewPurchaseOrder = () => {
    const { poStatus } = useParams();
    const [page, setPage] = useState(1);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [purchaseOrderStatusData, setPurchaseOrderStatusData] = useState({});
    const { purchaseOrder, loading } = useSelector(getPurchaseOrders);
    const { products, loading: productLoading } = useSelector(getProducts);
    const { customer, loading: customerLoading } = useSelector(getCustomers);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(
            fetchPurchaseOrders({ status: poStatus, populate: true, page })
        );
        dispatch(fetchProducts());
        dispatch(fetchCustomers());
    }, [page, poStatus]);

    const formik = useFormik({
        initialValues: {
            from: moment().subtract(1, 'months').startOf('day').valueOf(),
            to: moment().endOf('day').valueOf(),
        },
        onSubmit: async (values) => {
            const string = QueryString.stringify({
                populate: true,
                page: 1,
                limit: 5000,
                createdAt: {
                    $gte: formik.values.from,
                    $lte: formik.values.to,
                },
            });
            const response = await authAxiosInstance.get(
                `/purchaseOrder?${string}`
            );
            if (response?.data?.data?.docs?.length > 0) {
                let updatedData = response?.data?.data?.docs?.map((d) => {
                    return {
                        ...d,
                        customer: d.customer.name,
                        company: d?.customer?.companyData?.name,
                        product: d.product.name,
                        createdAt: moment(d.createdAt).format(
                            'DD/MM/YYY HH:mm'
                        ),
                        updatedAt: moment(d.updatedAt).format(
                            'DD/MM/YYY HH:mm'
                        ),
                    };
                });
                exportCsv(updatedData);
            } else {
                alert('No Data Found');
            }
        },
    });

    const columns = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + purchaseOrder?.pagingCounter;
            },
        },
        {
            Header: 'Date',
            Cell: (prop) => {
                const { data, row } = prop;
                return moment(row?.original?.createdAt).format(
                    'DD/MM/YYYY HH:mm'
                );
            },
        },
        {
            Header: 'ORD_Number',
            accessor: 'orderNumber',
        },
        {
            Header: 'Customer',
            accessor: 'customer.name',
        },
        {
            Header: 'Company',
            accessor: 'customer.companyData.name',
        },
        {
            Header: 'Product',
            accessor: 'product.name',
        },
        {
            Header: 'Status',
            accessor: 'status',
        },
        {
            Header: 'Cyan',
            accessor: 'cyan',
        },
        {
            Header: 'Magenta',
            accessor: 'magenta',
        },
        {
            Header: 'Black',
            accessor: 'black',
        },
        {
            Header: 'Yellow',
            accessor: 'yellow',
        },
        {
            Header: 'Cleaning Solution',
            accessor: 'cleaningSolution',
        },
        {
            Header: 'Dispatch Note',
            accessor: 'dispatchNote',
        },
        {
            Header: 'Action',
            Cell: (prop) => {
                const { data, row } = prop;
                return (
                    <div className="flex items-center gap-2 cursor-pointer">
                        <PrimaryButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsStatusModalOpen(true);
                                setPurchaseOrderStatusData(row?.original);
                            }}
                            type="button"
                        >
                            Update Status
                        </PrimaryButton>
                    </div>
                );
            },
        },
    ];
    const columnsMemo = useMemo(() => columns, [columns]);
    const dataMemo = useMemo(
        () => (purchaseOrder.docs ? purchaseOrder.docs : []),
        [purchaseOrder.docs]
    );

    //global search
    const debouncedSearch = useCallback(
        _.debounce((search) => {
            dispatch(
                fetchPurchaseOrders({
                    populate: true,
                    search,
                    page,
                })
            );
        }, 300),
        []
    );

    return (
        <PageWithCard heading="View Purchase Order">
            <PurchaseOrderStatusModal
                isStatusModalOpen={isStatusModalOpen}
                setIsStatusModalOpen={setIsStatusModalOpen}
                purchaseOrderStatusData={purchaseOrderStatusData}
                page={page}
                currentStatus={poStatus}
            />
            {/* <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-2"
            >
                <FormikSelectGroup
                    formik={formik}
                    name={'product'}
                    label="Product"
                    options={generateOptions({
                        array: products?.docs ?? [],
                        labelField: 'name',
                        valueField: '_id',
                    })}
                />
                <FormikSelectGroup
                    formik={formik}
                    name={'customer'}
                    label="Customer"
                    options={generateOptions({
                        array: customer?.docs ?? [],
                        labelField: 'name',
                        valueField: '_id',
                    })}
                />
                <FormikSelectGroup
                    formik={formik}
                    name={'status'}
                    label="Status"
                    options={PURCHASE_ORDER_STATUS}
                />
                <div className="mb-2">
                    {formik.isSubmitting ||
                    loading ||
                    productLoading ||
                    customerLoading ? (
                        <ClipLoader />
                    ) : (
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    )}
                </div>
            </form> */}
            <ShowDropdown heading={'Reporting'} allowedRoles={['admin']}>
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 mb-4"
                >
                    <div className="flex gap-5">
                        <FormikInputDateGroup
                            formik={formik}
                            name={'from'}
                            label="From"
                            required
                        />
                        <FormikInputDateGroup
                            formik={formik}
                            name="to"
                            label="To"
                            required
                        />
                    </div>
                    <div>
                        <PrimaryButton
                            type="submit"
                            disabled={formik.isSubmitting}
                        >
                            Submit
                        </PrimaryButton>
                    </div>
                </form>
            </ShowDropdown>
            <div className="flex gap-4 my-4">
                {PURCHASE_ORDER_STATUS.map((ele) => {
                    if (ele.value == poStatus) {
                        return <SecondaryButton>{ele.label}</SecondaryButton>;
                    } else {
                        return (
                            <PrimaryButton
                                onClick={() =>
                                    navigate(
                                        `/purchaseOrder/view-purchaseOrder/${ele.value}`
                                    )
                                }
                            >
                                {ele.label}
                            </PrimaryButton>
                        );
                    }
                })}
            </div>

            <TableWithHeadingAndGlobalSearch
                heading={`View Purchase Order`}
                tableClass="max-h-[350px] overflow-x-auto"
                loading={loading}
                columns={columnsMemo}
                data={dataMemo}
                searchFunction={(value) => {
                    debouncedSearch(value);
                }}
            />
            <PaginationClassic
                paginationDetails={{
                    ...purchaseOrder,
                    docs: purchaseOrder.docs,
                }}
                setPage={setPage}
            />
        </PageWithCard>
    );
};

export default ViewPurchaseOrder;
