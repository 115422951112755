import React from 'react';

const SetButtons = ({ children }) => {
    return (
        <div
            className="flex flex-row gap-2 items-center justify-start mb-4"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {children}
        </div>
    );
};

export default SetButtons;
