import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteCustomers,
    fetchCustomers,
    getCustomers,
} from '../../app/reducers/Customers/customerSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import { Edit2, Trash } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import ProductContainer from './ProductContainer';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import moment from 'moment';

const ViewCustomer = () => {
    const { user } = useSelector(getAuth);
    const [page, setPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const { customer, loading } = useSelector(getCustomers);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(
            fetchCustomers({
                populate: true,
                page,
                // "dispatchLocation._id": user.location,
            })
        );
    }, [page]);

    const columns = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + customer?.pagingCounter;
            },
        },
        {
            Header: 'Date',
            Cell: ({ row }) => {
                return (
                    <div>
                        {moment(row.original.createdAt).format('DD/MM/YYYY')}
                    </div>
                );
            },
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Company Name',
            accessor: 'company.0.name',
        },
        {
            Header: 'Phone',
            accessor: 'phone',
        },
        {
            Header: 'Dispatch Location',
            accessor: 'dispatchLocation.name',
        },
        {
            Header: 'Address',
            Cell: ({ row }) => {
                return (
                    <div className="max-w-xs text-wrap">
                        {row.original.address}
                    </div>
                );
            },
            accessor: 'address',
        },
        {
            Header: 'Machine',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                            setModalData(row?.original?.machine);
                        }}
                        className="text text-blue-700 underline hover:cursor-pointer"
                    >
                        View Machine
                    </div>
                    // <div className="flex flex-col gap-2">
                    //     {row?.original?.machine?.map((el, i) => (
                    //         <div key={el?._id}>
                    //             <div className="flex flex-row gap-1">
                    //                 <p>{i + 1}.</p>
                    //                 <div style={{ position: 'relative' }}>
                    //                     <img
                    //                         src={`${baseUrl}${row.original?.photo}`}
                    //                         style={{
                    //                             width: '100px',
                    //                             height: '100px',
                    //                         }}
                    //                     />
                    //                 </div>
                    //             </div>
                    //             <p>
                    //                 <span className="text text-base font-semibold">
                    //                     Name :
                    //                 </span>
                    //                 {el?.name}
                    //             </p>
                    //         </div>
                    //     ))}
                    // </div>
                );
            },
        },
        {
            Header: 'INK',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                            setModalData(row?.original?.ink);
                        }}
                        className="text text-blue-700 underline hover:cursor-pointer"
                    >
                        View Ink
                    </div>
                    // <div className="flex flex-col gap-2">
                    //     {row?.original?.ink?.map((el, i) => (
                    //         <div key={el?._id}>
                    //             <div className="flex flex-row gap-1">
                    //                 <p>{i + 1}.</p>
                    //                 <div style={{ position: 'relative' }}>
                    //                     <img
                    //                         src={`${baseUrl}${row.original?.photo}`}
                    //                         style={{
                    //                             width: '100px',
                    //                             height: '100px',
                    //                         }}
                    //                     />
                    //                 </div>
                    //             </div>
                    //             <p>
                    //                 <span className="text text-base font-semibold">
                    //                     Name :
                    //                 </span>
                    //                 {el?.name}
                    //             </p>
                    //         </div>
                    //     ))}
                    // </div>
                );
            },
        },
        {
            Header: 'Action',
            Cell: (prop) => {
                const { data, row } = prop;
                return (
                    <div
                        className="flex items-center gap-2 cursor-pointer"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Edit2
                            color="blue"
                            onClick={() => {
                                navigate(
                                    `/users/edit-customer/${row?.original?._id}`
                                );
                            }}
                        />

                        <Trash
                            color="red"
                            onClick={async () => {
                                await dispatch(
                                    deleteCustomers({ id: row?.original?._id })
                                );
                                await dispatch(
                                    fetchCustomers({ populate: true, page })
                                );
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    const customerMemoData = useMemo(() => customer?.docs ?? [], [customer]);
    const columnsMemoData = useMemo(() => columns, [columns]);

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            dispatch(fetchCustomers({ populate: true, page, search }));
        }, 300),
        [customer?.docs]
    );
    return (
        <PageWithCard heading="View Customer">
            <ProductContainer
                modalData={modalData}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
            />
            <TableWithHeadingAndGlobalSearch
                heading={`View Customer`}
                tableClass="max-h-[350px] overflow-x-auto"
                loading={loading}
                columns={columnsMemoData}
                data={customerMemoData}
                searchFunction={(value) => {
                    debouncedSearch(value);
                }}
            />
            <PaginationClassic
                paginationDetails={{
                    ...customer,
                    docs: customer.docs,
                }}
                setPage={setPage}
            />
        </PageWithCard>
    );
};

export default ViewCustomer;
