import React from 'react';
import { Briefcase, Edit, Mail, Monitor, User, UserPlus } from 'react-feather';
import SidebarGroup from '../../components/infrastructure/SidebarGroup';
import SidebarSingle from '../../components/infrastructure/SidebarSingle';
import SidebarTopText from '../../components/infrastructure/SidebarTopText';
import { LINKS } from '../../utils/links';

const SidebarLinks = ({ sidebarOpen, sidebarExpanded, setSidebarExpanded }) => {
    return (
        <ul className="mt-3">
            {/* Dashboard */}
            <SidebarTopText text="Application" sidebarOpen={sidebarOpen} />
            {/* <SidebarSingle
                allowedRoles={['admin']}
                activePath="/"
                name="Dashboard"
                Icon={<Monitor />}
                sidebarOpen={sidebarOpen}
            />

            <SidebarGroup
                links={[
                    {
                        label: 'Add Employee',
                        link: '/users/add-employee',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'View Employee',
                        link: '/users/view-employee',
                        allowedRoles: ['admin'],
                    },
                    {
                        label: 'Edit Employee',
                        link: '/users/edit-employee',
                        allowedRoles: ['admin'],
                    },
                ]}
                heading={'Users'}
                Icon={<User />}
                setSidebarExpanded={setSidebarExpanded}
                sidebarExpanded={sidebarExpanded}
            />
            <SidebarSingle
                allowedRoles={['admin']}
                activePath="/media-library"
                name="Media Library"
                Icon={<Briefcase />}
                sidebarOpen={sidebarOpen}
            />
            <SidebarSingle
                allowedRoles={['admin']}
                activePath="/generalBulkUpload"
                name="General Bulk Upload"
                Icon={<Briefcase />}
                sidebarOpen={sidebarOpen}
            /> */}

            {LINKS.map((item) =>
                item.type == 'single' ? (
                    <SidebarSingle
                        allowedRoles={item.allowedRoles}
                        activePath={item.link}
                        name={item.label}
                        Icon={item.Icon}
                        sidebarOpen={sidebarOpen}
                    />
                ) : (
                    <SidebarGroup
                        links={item.links}
                        heading={item.heading}
                        Icon={item.Icon}
                        setSidebarExpanded={setSidebarExpanded}
                        sidebarExpanded={sidebarExpanded}
                    />
                )
            )}
        </ul>
    );
};

export default SidebarLinks;
