import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'purchaseOrder';

export const fetchPurchaseOrders = fetchData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editPurchaseOrder = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createPurchaseOrder = createData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const deletePurchaseOrder = deleteData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const fetchPurchaseOrder = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);

const purchaseOrderSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchPurchaseOrders, SLICE_NAME),
        ...editData.generateExtraReducers(editPurchaseOrder, SLICE_NAME),
        ...createData.generateExtraReducers(createPurchaseOrder, SLICE_NAME),
        ...deleteData.generateExtraReducers(deletePurchaseOrder, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchPurchaseOrder, SLICE_NAME),
    },
});

export const getPurchaseOrders = (state) => state.purchaseOrder;
export default purchaseOrderSlice.reducer;
