import React from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { PURCHASE_ORDER_STATUS } from '../../utils/dropdownOptions';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import {
    editPurchaseOrder,
    fetchPurchaseOrders,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';

const PurchaseOrderStatusModal = ({
    isStatusModalOpen,
    setIsStatusModalOpen,
    purchaseOrderStatusData,
    page,
    currentStatus,
}) => {
    const currentStatusIndex = PURCHASE_ORDER_STATUS.findIndex(
        (item) => item.value == purchaseOrderStatusData.status
    );
    const nextStatus =
        PURCHASE_ORDER_STATUS[
            currentStatusIndex != PURCHASE_ORDER_STATUS.length - 1
                ? currentStatusIndex + 1
                : currentStatusIndex
        ].value;
    const dispatch = useDispatch();
    const statusFormik = useFormik({
        enableReinitialize: true,
        initialValues: { ...purchaseOrderStatusData, status: nextStatus ?? '' },
        onSubmit: async (values) => {
            await dispatch(editPurchaseOrder(values));
            await dispatch(
                fetchPurchaseOrders({
                    status: currentStatus,
                    populate: true,
                    page,
                })
            );
            statusFormik.resetForm();
            setIsStatusModalOpen(false);
        },
    });
    return (
        <ModalBasic
            setModalOpen={setIsStatusModalOpen}
            modalOpen={isStatusModalOpen}
            title={'Update Status'}
        >
            <form
                onSubmit={statusFormik.handleSubmit}
                className="flex flex-col gap-2 p-3"
            >
                <FormikSelectGroup
                    formik={statusFormik}
                    name={'status'}
                    label="Status"
                    options={PURCHASE_ORDER_STATUS}
                />
                <FormikTextareaGroup
                    formik={statusFormik}
                    name={'dispatchNote'}
                    label="Message"
                    // readOnly={statusFormik.values.status !== 'dispatch'}
                />
                <div className="mb-2">
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default PurchaseOrderStatusModal;
