import React from 'react';
import MediaLibraryAssets from '../../pages/MediaLibrary/MediaLibraryAssets';

const PageWithCard = ({ children, heading = '' }) => {
    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                {/* Page header */}
                <div className="mb-8">
                    {/* Title */}
                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                        {heading}
                    </h1>
                </div>

                {/* Content */}
                <div className="bg-white shadow-lg rounded-sm mb-8">
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div>{children}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageWithCard;
