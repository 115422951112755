import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'customer';

export const fetchCustomers = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editCustomers = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createCustomers = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteCustomers = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchCustomer = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);

const customersSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchCustomers, SLICE_NAME),
        ...editData.generateExtraReducers(editCustomers, SLICE_NAME),
        ...createData.generateExtraReducers(createCustomers, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteCustomers, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchCustomer, SLICE_NAME),
    },
});

export const getCustomers = (state) => state.customer;
export default customersSlice.reducer;
