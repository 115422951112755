import React from 'react';
import { useDispatch } from 'react-redux';
import {
    editComplaint,
    fetchComplaints,
} from '../../app/reducers/Complaints/complaintSlice';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { useFormik } from 'formik';

const AssignTechnicianModal = ({
    isAssignTechnicianBtnOpen,
    setIsAssignTechnicianBtnOpen,
    assignTechnicianData,
    page,
    complaintStatus,
    activeButtonIndex,
}) => {
    const dispatch = useDispatch();

    const assignedFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...assignTechnicianData,
            assignedTo: '',
        },
        onSubmit: async (values) => {
            await dispatch(editComplaint({ ...values, status: 'InProgress' }));
            await dispatch(
                fetchComplaints({
                    populate: true,
                    page,
                    status: `${complaintStatus[activeButtonIndex]}`,
                })
            );
            setIsAssignTechnicianBtnOpen(false);
            assignedFormik.resetForm();
        },
    });
    return (
        <ModalBasic
            title={`Assign Technician`}
            setModalOpen={setIsAssignTechnicianBtnOpen}
            modalOpen={isAssignTechnicianBtnOpen}
        >
            <form
                onSubmit={assignedFormik.handleSubmit}
                className="flex flex-col gap-3 p-3"
            >
                <FormikInputGroup
                    name="assignedTo"
                    formik={assignedFormik}
                    label="Assign Technician"
                />
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default AssignTechnicianModal;
