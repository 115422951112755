import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../partials/Header';
import Sidebar from '../../partials/SideBar/Sidebar';
import {
    getConfirmPopUp,
    openConfirmPopUp,
} from '../../app/reducers/ConfirmPopUp/confirmPopUpSlice';
import ConfirmPopUp from '../infrastructure/Modals/GlobalPopUp/ConfirmPopUp';
import { useDispatch, useSelector } from 'react-redux';

const RouteWithHeaderAndSidebar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const confirmPopUp = useSelector(getConfirmPopUp);
    const dispatch = useDispatch();
    return (
        <div className="flex h-screen overflow-hidden">
            {' '}
            <Sidebar
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />{' '}
            {/*  Site header */}
            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />
                <main className="relative">
                    <Outlet />
                </main>
                {confirmPopUp?.isOpen && (
                    <ConfirmPopUp
                        isOpen={confirmPopUp.isOpen}
                        setIsOpen={() =>
                            dispatch(
                                openConfirmPopUp({
                                    isOpen: false,
                                    message: '',
                                    data: {},
                                })
                            )
                        }
                        data={confirmPopUp}
                    />
                )}
            </div>
        </div>
    );
};

export default RouteWithHeaderAndSidebar;
