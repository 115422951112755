import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authAxiosInstance, axiosInstance } from '../../../utils/axiosConfig';

const SLICE_NAME = 'generalBulkUpload';

export const fetchCollections = createAsyncThunk(
    'generalBulkUpload/fetchCollections',
    async (data) => {
        const getCollections = await authAxiosInstance.get('generalBulkUpload');
        console.log('getCollections', getCollections);
        return getCollections.data.data;
    }
);

const initialState = {
    collections: [],
};

const generalBulkUpload = createSlice({
    name: SLICE_NAME,
    initialState,
    //   reducers: {
    //     setEditId: generateSetEditId(),
    //     resetEditData: resetData(generateStateSingle(SLICE_NAME)),
    //   },
    extraReducers: {
        [fetchCollections.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchCollections.fulfilled]: (state, action) => {
            state.collections = action.payload;
            state.loading = false;
        },
        [fetchCollections.rejected]: (state, action) => {
            state.loading = false;
        },
    },
});

export const getGeneralBulkUpload = (state) => state.generalBulkUpload;
// export const { setEditId, resetEditData } = categorySlice.actions;
export default generalBulkUpload.reducer;
