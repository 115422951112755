import { createSlice } from '@reduxjs/toolkit';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'complaint';

export const fetchComplaints = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editComplaint = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createComplaint = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteComplaint = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchComplaint = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);

const complaintsSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchComplaints, SLICE_NAME),
        ...editData.generateExtraReducers(editComplaint, SLICE_NAME),
        ...createData.generateExtraReducers(createComplaint, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteComplaint, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchComplaint, SLICE_NAME),
    },
});

export const getComplaints = (state) => state.complaint;
export default complaintsSlice.reducer;
