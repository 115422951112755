import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    editCustomers,
    fetchCustomer,
    getCustomers,
} from '../../app/reducers/Customers/customerSlice';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import { generateOptions } from '../../utils/Utils';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import {
    fetchDispatchLocations,
    getDispatchLocation,
} from '../../app/reducers/DispatchLocation/dispatchLocationSlice';
import {
    fetchCompanies,
    fetchCompany,
    getCompanies,
} from '../../app/reducers/Company/companySlice';

const EditCustomer = () => {
    const { id } = useParams();
    const { elementEditData, editDataLoading } = useSelector(getCustomers);
    const { company } = useSelector(getCompanies);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCompanies());

        dispatch(fetchCustomer({ _id: id }));
    }, [id]);

    const { dispatchLocation } = useSelector(getDispatchLocation);

    useEffect(() => {
        dispatch(fetchDispatchLocations());
    }, []);

    const dispatchLocationData = useMemo(
        () => (dispatchLocation.docs ? dispatchLocation.docs : []),
        [dispatchLocation.docs]
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { ...elementEditData },
        onSubmit: async (values) => {
            // check for empty object
            const filteredMachineArray = values?.machine?.filter(
                (el) => Object.keys(el).length !== 0
            );
            const filteredInkArray = values?.ink?.filter(
                (el) => Object.keys(el).length !== 0
            );
            values.machine = filteredMachineArray;
            values.ink = filteredInkArray;
            await dispatch(editCustomers(values));
            await dispatch(fetchCustomer({ _id: id }));
        },
    });
    return (
        <PageWithCard>
            {editDataLoading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-3"
                >
                    <FormikInputGroup
                        formik={formik}
                        label="Name"
                        name="name"
                        required
                    />
                    <FormikSelectGroup
                        label="Location"
                        name="dispatchLocation"
                        formik={formik}
                        options={generateOptions({
                            array: dispatchLocationData ?? [],
                            labelField: 'name',
                            valueField: '_id',
                        })}
                    />
                    {/* <FormikInputGroup
                        formik={formik}
                        label="Company Name"
                        name="companyName"
                    /> */}
                    <FormikSelectGroup
                        formik={formik}
                        name={'company'}
                        label="Company"
                        options={generateOptions({
                            array: company.docs ?? [],
                            labelField: 'name',
                            valueField: '_id',
                        })}
                        onChange={async (selectedOption) => {
                            formik.setFieldValue(
                                `company`,
                                selectedOption.value
                            );
                            const resp = await dispatch(
                                fetchCompany({ _id: selectedOption.value })
                            );
                            if (resp?.payload?.data?.docs.length > 0) {
                                const { machine, ink } =
                                    resp?.payload?.data?.docs[0];
                                formik.setFieldValue(`machine`, machine);
                                formik.setFieldValue(`ink`, ink);
                            } else {
                                formik.setFieldValue(`machine`, []);
                                formik.setFieldValue(`ink`, []);
                            }
                        }}
                        required
                    />
                    <FormikTextareaGroup
                        formik={formik}
                        label="Address"
                        name="address"
                    />

                    <FormikPhoneInputGroup
                        name="phone"
                        required
                        formik={formik}
                        label="Phone"
                    />
                    {/* machine */}
                    {formik.values.name && (
                        <FormikProvider value={formik}>
                            <FieldArray
                                name="machine"
                                render={(arrayHelpers) => {
                                    return (
                                        <div className="flex flex-col gap-2 border p-2 rounded-md">
                                            <label className="text text-base font-semibold">
                                                Machine
                                            </label>
                                            <div>
                                                {formik?.values?.machine?.map(
                                                    (ele, index) => (
                                                        <div
                                                            className="relative p-4 mb-2"
                                                            style={{
                                                                border: '1px solid #d6c7c7',
                                                                borderRadius:
                                                                    '5px',
                                                            }}
                                                            key={index}
                                                        >
                                                            <FormikAsyncSelect
                                                                label="Selected Machine"
                                                                formik={formik}
                                                                name={`machine.${index}`}
                                                                getOptions={async (
                                                                    value
                                                                ) => {
                                                                    if (
                                                                        !value
                                                                    ) {
                                                                        return [];
                                                                    }
                                                                    try {
                                                                        const string =
                                                                            QueryString.stringify(
                                                                                {
                                                                                    search: value,
                                                                                    type: {
                                                                                        $ne: 'INK',
                                                                                    },
                                                                                }
                                                                            );

                                                                        const productsResp =
                                                                            await authAxiosInstance.get(
                                                                                `/products?${string}`
                                                                            );

                                                                        const options =
                                                                            productsResp?.data?.data?.docs?.map(
                                                                                (
                                                                                    ele
                                                                                ) => ({
                                                                                    label: ele?.name,
                                                                                    value: ele?._id,
                                                                                })
                                                                            );

                                                                        return options;
                                                                    } catch (error) {
                                                                        console.log(
                                                                            error
                                                                        );
                                                                    }
                                                                }}
                                                                onChange={(
                                                                    selectedValue
                                                                ) => {
                                                                    //check whether same id is used or not
                                                                    const isSameMachineExist =
                                                                        formik?.values?.machine?.find(
                                                                            (
                                                                                el
                                                                            ) =>
                                                                                el ===
                                                                                selectedValue.value
                                                                        );
                                                                    if (
                                                                        isSameMachineExist
                                                                    ) {
                                                                        arrayHelpers.remove(
                                                                            index
                                                                        );
                                                                        return toast.error(
                                                                            'Already Selected above'
                                                                        );
                                                                    }
                                                                    //machineId
                                                                    formik.setFieldValue(
                                                                        `machine.${index}`,
                                                                        selectedValue.value
                                                                    );
                                                                }}
                                                                isDisabled
                                                            />
                                                            {/* <div className="mt-6 cursor-pointer">
                                                                <DangerButton
                                                                    type="button"
                                                                    onClick={() =>
                                                                        arrayHelpers.remove(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    Remove
                                                                </DangerButton>
                                                            </div> */}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            {/* <div>
                                                <SecondaryButton
                                                    onClick={() => {
                                                        arrayHelpers.push({});
                                                    }}
                                                    type="button"
                                                >
                                                    Add More
                                                </SecondaryButton>
                                            </div> */}
                                        </div>
                                    );
                                }}
                            />
                        </FormikProvider>
                    )}

                    {/* ink */}
                    {formik.values.name && (
                        <FormikProvider value={formik}>
                            <FieldArray
                                name="ink"
                                render={(arrayHelpers) => {
                                    return (
                                        <div className="flex flex-col gap-2 border p-2 rounded-md">
                                            <label className="text text-base font-semibold ">
                                                Ink
                                            </label>
                                            <div>
                                                {formik?.values?.ink?.map(
                                                    (ele, index) => (
                                                        <div
                                                            className="relative p-4 mb-2"
                                                            style={{
                                                                border: '1px solid #d6c7c7',
                                                                borderRadius:
                                                                    '5px',
                                                            }}
                                                            key={index}
                                                        >
                                                            <FormikAsyncSelect
                                                                label="Selected Ink"
                                                                formik={formik}
                                                                name={`ink.${index}`}
                                                                getOptions={async (
                                                                    value
                                                                ) => {
                                                                    if (
                                                                        !value
                                                                    ) {
                                                                        return [];
                                                                    }
                                                                    try {
                                                                        const string =
                                                                            QueryString.stringify(
                                                                                {
                                                                                    search: value,
                                                                                    type: {
                                                                                        $ne: 'MACHINE',
                                                                                    },
                                                                                }
                                                                            );

                                                                        const productsResp =
                                                                            await authAxiosInstance.get(
                                                                                `/products?${string}`
                                                                            );
                                                                        const options =
                                                                            productsResp?.data?.data?.docs?.map(
                                                                                (
                                                                                    ele
                                                                                ) => ({
                                                                                    label: ele?.name,
                                                                                    value: ele?._id,
                                                                                })
                                                                            );
                                                                        return options;
                                                                    } catch (error) {
                                                                        console.log(
                                                                            error
                                                                        );
                                                                    }
                                                                }}
                                                                onChange={(
                                                                    selectedValue
                                                                ) => {
                                                                    //check whether same id is used or not
                                                                    const isSameInkExist =
                                                                        formik?.values?.ink?.find(
                                                                            (
                                                                                el
                                                                            ) =>
                                                                                el ===
                                                                                selectedValue.value
                                                                        );
                                                                    if (
                                                                        isSameInkExist
                                                                    ) {
                                                                        arrayHelpers.remove(
                                                                            index
                                                                        );
                                                                        return toast.error(
                                                                            'Already Selected above'
                                                                        );
                                                                    }
                                                                    //Ink Id
                                                                    formik.setFieldValue(
                                                                        `ink.${index}`,
                                                                        selectedValue.value
                                                                    );
                                                                }}
                                                                isDisabled
                                                            />
                                                            {/* <div className="mt-6 cursor-pointer">
                                                                <DangerButton
                                                                    type="button"
                                                                    onClick={() =>
                                                                        arrayHelpers.remove(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    Remove
                                                                </DangerButton>
                                                            </div> */}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            {/* <div>
                                                <SecondaryButton
                                                    onClick={() => {
                                                        arrayHelpers.push({});
                                                    }}
                                                    type="button"
                                                >
                                                    Add More
                                                </SecondaryButton>
                                            </div> */}
                                        </div>
                                    );
                                }}
                            />
                        </FormikProvider>
                    )}
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </PageWithCard>
    );
};

export default EditCustomer;
