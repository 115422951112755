import React from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { Edit2, Trash } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';

import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import EditDispatchLocationModal from './EditDispatchLocationModal';
import {
    fetchDispatchLocations,
    setEditId,
    deleteDispatchLocation,
    getDispatchLocation,
} from '../../app/reducers/DispatchLocation/dispatchLocationSlice';

const ViewDispatchLocation = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchDispatchLocations({ populate: true }));
    }, []);

    const { loading, dispatchLocation } = useSelector(getDispatchLocation);
    const columns = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + 1;
            },
        },
        {
            Header: 'Location Name',
            accessor: 'name',
        },

        {
            Header: 'Action',
            Cell: (prop) => {
                const { data, row } = prop;
                return (
                    <div
                        className="flex gap-2"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Edit2
                            color="blue"
                            onClick={async () => {
                                await dispatch(setEditId(row?.original?._id));
                            }}
                        />
                        <Trash
                            color="red"
                            onClick={async () => {
                                await dispatch(
                                    deleteDispatchLocation(row?.original?._id)
                                );
                            }}
                        />
                    </div>
                );
            },
        },
    ];
    const columnsMemo = useMemo(() => columns, [columns]);
    const data = useMemo(
        () => (dispatchLocation.docs ? dispatchLocation.docs : []),
        [dispatchLocation.docs]
    );
    const sortByName = [...data].sort((a, b) => (a.name < b.name ? -1 : 1));
    return (
        <PageWithCard heading="View Locations">
            {loading ? (
                <ClipLoader />
            ) : (
                <>
                    <EditDispatchLocationModal />
                    <TableWithHeadingAndSearch
                        heading="Locations"
                        columns={columnsMemo}
                        data={sortByName}
                    />
                </>
            )}
        </PageWithCard>
    );
};

export default ViewDispatchLocation;
