import { createSlice } from '@reduxjs/toolkit';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    createWithFile,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';

const SLICE_NAME = 'employees';

export const fetchEmployees = fetchData.generateThunk(
    SLICE_NAME,
    'users/employee'
);
export const createEmployee = createData.generateThunk(
    SLICE_NAME,
    'users/employee/register'
);
export const deleteEmployee = deleteData.generateThunk(
    SLICE_NAME,
    'users/employee'
);
export const fetchEmployee = fetchEditData.generateThunk(
    SLICE_NAME,
    'users/employee'
);
export const editEmployee = editData.generateThunk(
    SLICE_NAME,
    'users/employee/update'
);

const employeeSlice = createSlice({
    initialState: generateStateSingle(SLICE_NAME),
    name: SLICE_NAME,
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchEmployees, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchEmployee, SLICE_NAME),
        ...createData.generateExtraReducers(createEmployee, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteEmployee, SLICE_NAME),
        ...editData.generateExtraReducers(editEmployee, SLICE_NAME),
    },
});

export const getEmployees = (state) => state.employees;

export default employeeSlice.reducer;
