import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authAxiosInstance, axiosInstance } from '../../../utils/axiosConfig';
import { generateSetEditId } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'dispatchLocation';

export const fetchDispatchLocations = fetchData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editDispatchLocation = editData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const fetchDispatchLocation = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const deleteDispatchLocation = deleteData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const createDispatchLocation = createData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);

const dispatchLocationSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    reducers: {
        setEditId: generateSetEditId(),
    },
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchDispatchLocations, SLICE_NAME),
        ...createData.generateExtraReducers(createDispatchLocation, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteDispatchLocation, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(
            fetchDispatchLocation,
            SLICE_NAME
        ),
        ...editData.generateExtraReducers(editDispatchLocation, SLICE_NAME),
    },
});

export const getDispatchLocation = (state) => state.dispatchLocation;
export const { setEditId } = dispatchLocationSlice.actions;
export default dispatchLocationSlice.reducer;
