import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/Auth/authSlice';
import mediaLibrarySlice from './reducers/MediaLibrary/mediaLibrarySlice';
import emailSlice from './reducers/Emails/emailSlice';
import usersSlice from './reducers/Users/usersSlice';
import EmployeeSlice from './reducers/Users/EmployeeSlice';
import generalBulkUploadSlice from './reducers/GeneralBulkUpload/generalBulkUploadSlice';
import productSlice from './reducers/Products/productSlice';
import customerSlice from './reducers/Customers/customerSlice';
import complaintSlice from './reducers/Complaints/complaintSlice';
import confirmPopUpSlice from './reducers/ConfirmPopUp/confirmPopUpSlice';
import purchaseOrderSlice from './reducers/PurchaseOrder/purchaseOrderSlice';
import dispatchLocationSlice from './reducers/DispatchLocation/dispatchLocationSlice';
import companySlice from './reducers/Company/companySlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        mediaLibrary: mediaLibrarySlice,
        emails: emailSlice,
        users: usersSlice,
        employees: EmployeeSlice,
        generalBulkUpload: generalBulkUploadSlice,
        products: productSlice,
        customer: customerSlice,
        complaint: complaintSlice,
        confirmPopUp: confirmPopUpSlice,
        purchaseOrder: purchaseOrderSlice,
        dispatchLocation: dispatchLocationSlice,
        company: companySlice,
    },
});
