import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchComplaints,
    getComplaints,
} from '../../app/reducers/Complaints/complaintSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import CloseComplaintModal from './CloseComplaintModal';
import AssignTechnicianModal from './AssignTechnicianModal';
import SetButtons from '../../components/infrastructure/SetButtons';
import { Link } from 'react-router-dom';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import { useFormik } from 'formik';
import moment from 'moment';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { exportCsv } from '../../utils/Utils';

const ViewComplaint = () => {
    //state
    const [page, setPage] = useState(1);
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    const [isCloseComplaintBtnOpen, setIsCloseComplaintBtnOpen] =
        useState(false);
    const [closeComplaintData, setCloseComplaintData] = useState({});
    const [isAssignTechnicianBtnOpen, setIsAssignTechnicianBtnOpen] =
        useState(false);
    const [assignTechnicianData, setAssignTechnicianData] = useState({});
    //redux
    const { complaint, loading } = useSelector(getComplaints);
    const { baseUrl } = useSelector(getMediaLibrary);

    const dispatch = useDispatch();

    const complaintStatus = ['open', 'InProgress', 'closed'];

    useEffect(() => {
        dispatch(
            fetchComplaints({
                populate: true,
                page,
                status: `${complaintStatus[activeButtonIndex]}`,
            })
        );
    }, [page, activeButtonIndex, dispatch]);

    const formik = useFormik({
        initialValues: {
            from: moment().subtract(1, 'months').startOf('day').valueOf(),
            to: moment().endOf('day').valueOf(),
        },
        onSubmit: async (values) => {
            const string = QueryString.stringify({
                populate: true,
                page: 1,
                limit: 5000,
                createdAt: {
                    $gte: formik.values.from,
                    $lte: formik.values.to,
                },
            });
            const response = await authAxiosInstance.get(
                `/complaint?${string}`
            );
            if (response?.data?.data?.docs?.length > 0) {
                let updatedData = response?.data?.data?.docs?.map((d) => {
                    return {
                        ...d,
                        customer: d.customer.name,
                        company: d?.customer?.companyData?.name,
                        product: d?.product?.name,
                        createdAt: moment(d.createdAt).format(
                            'DD/MM/YYY HH:mm'
                        ),
                        updatedAt: moment(d.updatedAt).format(
                            'DD/MM/YYY HH:mm'
                        ),
                    };
                });
                exportCsv(updatedData);
            } else {
                alert('No Data Found');
            }
        },
    });

    const columns = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + complaint?.pagingCounter;
            },
        },
        {
            Header: 'Date',
            Cell: (prop) => {
                const { data, row } = prop;
                return moment(row?.original?.createdAt).format(
                    'DD/MM/YYYY HH:mm'
                );
            },
        },
        {
            Header: 'Photos',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-2">
                        {row.original?.photos?.map &&
                            row.original.photos.map((ele) => (
                                <a href={`${baseUrl}${ele}`} target="_blank">
                                    <img src={`${baseUrl}${ele}`} />
                                </a>
                            ))}
                    </div>
                );
            },
        },
        {
            Header: 'Videos',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-2">
                        {row.original?.videos?.map &&
                            row.original.videos.map((ele, i) => (
                                <a
                                    className="text-indigo-500 underline"
                                    href={`${baseUrl}${ele}`}
                                    target="_blank"
                                >
                                    <p>Video {i + 1}</p>
                                </a>
                            ))}
                    </div>
                );
            },
        },
        {
            Header: 'Customer',
            accessor: 'customer.name',
        },
        {
            Header: 'Company',
            accessor: 'customer.companyData.name',
        },
        {
            Header: 'Machine',
            accessor: 'product.name',
        },
        {
            Header: 'Description',
            accessor: 'description',
        },
        {
            Header: 'Resolution',
            accessor: 'resolution',
        },
        {
            Header: 'Technician',
            accessor: 'assignedTo',
        },
        {
            Header: 'Status',
            accessor: 'status',
        },
        {
            Header: 'Action',
            Cell: (prop) => {
                const { data, row } = prop;
                return (
                    <div
                        className="flex  gap-2 cursor-pointer"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {/* <Trash
                            color="red"
                            onClick={async () => {
                                await dispatch(
                                    deleteComplaint({ id: row?.original?._id })
                                );
                                await dispatch(
                                    fetchComplaints({
                                        populate: true,
                                        page,
                                    })
                                );
                            }}
                        /> */}
                        <PrimaryButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsCloseComplaintBtnOpen(true);
                                setCloseComplaintData(row?.original);
                            }}
                            type="button"
                        >
                            Close Complaint
                        </PrimaryButton>
                        <PrimaryButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsAssignTechnicianBtnOpen(true);
                                setAssignTechnicianData(row?.original);
                            }}
                            type="button"
                        >
                            Assign Technician
                        </PrimaryButton>
                    </div>
                );
            },
        },
    ];

    const complaintMemoData = useMemo(() => complaint?.docs ?? [], [complaint]);
    const columnsMemoData = useMemo(() => columns, [columns]);

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            dispatch(
                fetchComplaints({
                    populate: true,
                    page,
                    search,
                    status: `${complaintStatus[activeButtonIndex]}`,
                })
            );
        }, 300),
        [complaint?.docs]
    );

    return (
        <PageWithCard heading="View Complaints">
            <div className="mb-3">
                <ShowDropdown heading={'Reporting'} allowedRoles={['admin']}>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="flex flex-col gap-4 mb-4"
                    >
                        <div className="flex gap-5">
                            <FormikInputDateGroup
                                formik={formik}
                                name={'from'}
                                label="From"
                                required
                            />
                            <FormikInputDateGroup
                                formik={formik}
                                name="to"
                                label="To"
                                required
                            />
                        </div>
                        <div>
                            <PrimaryButton
                                type="submit"
                                disabled={formik.isSubmitting}
                            >
                                Submit
                            </PrimaryButton>
                        </div>
                    </form>
                </ShowDropdown>
            </div>
            <>
                <SetButtons>
                    {complaintStatus?.map((ele, index) => (
                        <Link key={index}>
                            <button
                                className={
                                    activeButtonIndex === index
                                        ? `btn bg-slate-200 text-slate-500`
                                        : `btn bg-indigo-500 hover:bg-indigo-600 text-white `
                                }
                                onClick={() => setActiveButtonIndex(index)}
                            >
                                {ele}
                            </button>
                        </Link>
                    ))}
                </SetButtons>
            </>
            <CloseComplaintModal
                isCloseComplaintBtnOpen={isCloseComplaintBtnOpen}
                setIsCloseComplaintBtnOpen={setIsCloseComplaintBtnOpen}
                closeComplaintData={closeComplaintData}
                page={page}
                complaintStatus={complaintStatus}
                activeButtonIndex={activeButtonIndex}
            />
            <AssignTechnicianModal
                isAssignTechnicianBtnOpen={isAssignTechnicianBtnOpen}
                setIsAssignTechnicianBtnOpen={setIsAssignTechnicianBtnOpen}
                assignTechnicianData={assignTechnicianData}
                page={page}
                complaintStatus={complaintStatus}
                activeButtonIndex={activeButtonIndex}
            />
            <TableWithHeadingAndGlobalSearch
                heading={`View Complaints`}
                tableClass="max-h-[350px] overflow-x-auto"
                loading={loading}
                columns={columnsMemoData}
                data={complaintMemoData}
                searchFunction={(value) => {
                    debouncedSearch(value);
                }}
            />
            <PaginationClassic
                paginationDetails={{
                    ...complaint,
                    docs: complaint.docs,
                }}
                setPage={setPage}
            />
        </PageWithCard>
    );
};

export default ViewComplaint;
